require('../css/web.scss');
require('slick-carousel');
require('@fancyapps/fancybox');

$(document).ready(function () {
    let slider = $('.clients-slider');
    slider.slick({
        infinite: false,
        slidesToShow: 1,
        slidesPerRow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        // centerMode: true,
        arrows: false,
    });

    $('.clients .arrow.left').on('click', function () {
        slider.slick('slickPrev');
    });
    $('.clients .arrow.right').on('click', function () {
        slider.slick('slickNext');
    });

    let slider2 = $('.gallery-slider');
    let slider2_options = {
        infinite: false,
        slidesToShow: 4,
        slidesPerRow: 1,
        slidesToScroll: 1,
        variableWidth: false,
        centerMode: false,
        arrows: false,
    };
    slider2.slick(slider2_options);

    $('.gallery .arrow.left').on('click', function () {
        slider2.slick('slickPrev');
    });
    $('.gallery .arrow.right').on('click', function () {
        slider2.slick('slickNext');
    });
    $('[data-fancybox="gallery"]').fancybox({
        beforeClose: function () {
            slider2.slick('unslick');
            setTimeout(function () {
                slider2.slick(slider2_options);
            }, 1000);
        }
    });
});